.bq {
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  gap: 5rem;

  .best-quote {
    border-radius: 0.5rem;

    .content {
      padding: 5rem 8rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;

      .title {
        font-size: 2rem;
      }

      .description {
        font-size: 1.3rem;

        a {
          color: black;
          font-weight: bold;
        }
      }

      .link {
        color: black;
        font-weight: bold;
        text-decoration: none;
        display: flex;
        gap: 1rem;

        svg {
          font-size: 1.2rem;;
        }
      }
    }

  }

  .orange {
    background-color: var(--orange);
  }


  @media screen and (min-width:280px) and (max-width:1080px) {
    margin: 0;
    gap: 0;

    .best-quote {
      border-radius: 0;
      grid-template-columns: 1fr;
      padding: 1rem 2rem;
      gap: 2rem;

      .content {
        padding: 0;
      }

    }

  }
}