.posts {
  margin-top: 5rem;

    .title {
      text-align: center;
      color: var(--accent-color1);
      font-size: 2.4rem;
      margin-top: 2rem;
      margin-bottom: 5rem;
    }

    .description {
      color: var(--accent-color3);
      padding-bottom: 2rem;
      margin-top: 2rem;

      a {
        color: var(--link-references);
        text-decoration: none;
      }      

    }

    .long_description {
      color: var(--accent-color3);
      padding-bottom: 1rem;

      a {
        color: var(--link-references);
        text-decoration: none;
      }

    }

    .quote {
      color: var(--accent-color3);
      font-style: italic;
      padding-bottom: 1rem;
      margin-top: 1rem;
    }    

    .subtitle {
      text-align: center;
      color: var(--accent-color1);
      font-size: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .subsubtitle {
      text-align: center;
      color: var(--accent-color1);
      font-size: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 70%;
    }

    .source-container {
      margin-bottom: 5rem;
      .source {
        color: var(--accent-color3);
        padding-bottom: 2rem;
        margin-top: 2rem;
      }

      a {
        color: var(--link-references);
        text-decoration: none;
      }

    }

    .images {
      padding: 1.5rem 0;

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 30%;
        padding: 0.5rem 0;
      }

    }

    .side {
      display: flex;
      flex-direction: row;
      justify-content: center;

      img {
        flex: 1;
        max-width:30%;

      }
    }
}