footer {
  margin: 6rem 0;
  display: flex;
  flex-direction: column;
  gap: 5rem;

  .upper {
    display: flex;
    justify-content:space-between;
    color: var(--accent-color3);

    .brand-container {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;

      ul {
        list-style-type: none;
        display: flex;
        gap: 7rem;
        cursor: pointer;
      }

    }


  }


  @media screen and (min-width:280px) and (max-width:1080px) {
    margin: 0;
    gap: 2rem;
    padding: 1rem 2rem;

    .upper {
      flex-direction: column;
      gap: 2rem;

    }

  }
}

