.card {
  background-color: var(--card-color);
  width: max-content;
  border-radius: 1rem;
  &-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;

    .card-image {
      img {
        flex: 1;
        width: 2rem;
        height: 2rem;
      }
    }

    .card-heading {
      align-self: center;

      .card-series {
        color: var(--orange);
        text-transform: uppercase;
        font-size: 0.7rem;
      }

    }

  }
}