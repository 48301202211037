.about {
  margin-top: 5rem;
  margin-bottom: 25rem;

    .title {
      text-align: center;
      color: var(--accent-color1);
      font-size: 2.4rem;
      margin-top: 2rem;
      margin-bottom: 7rem;
    }

    .description {
      color: var(--accent-color3);
      padding-bottom: 2rem;

    }

}