nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .brand-container {
    .brand {
      img {
        height: 100%;
      }
    }
    .toggle-container {
      display: none;

      .mode {
        .dark {
          color: black;
        }

        .light {
          color: yellow;
        }
      }
    }
  }
  .links-container {
    .links {
      list-style-type: none;
      display: flex;
      gap: 4rem;

      li {
        .dark {
          color: black;
        }

        .light {
          color: yellow;
        }

        a {
          color: var(--accent-color1);
          text-decoration: none;
        }
        &:last-of-type {
          a {
            color: var(--pink);
          }
        }
      }
    }
  }
  @media screen and (min-width:280px) and (max-width:1080px) {
      position: relative;
      padding: 1rem 2rem;

      .brand-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .brand {
          img {
            height: 1.5rem;
          }
        }

        // menu only visible for smartphone
        .toggle-container {
          display: block;
          color: var(--accent-color1);
          display: flex;
          flex-direction: row-reverse;
          gap: 1rem;
          z-index: 40;

          .toggle {
            z-index: 40;
            display: block;
          }
        }
      }

    .links-container {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      width: 0;
      transition: 0.5s ease-in-out;
      z-index: 30;
      background-image: linear-gradient(rgba(255, 255, 255, 0),rgba(255, 255, 255, 0)), linear-gradient(101deg, var(--pink), var(--orange));

      .links {
        flex-direction: column;

        li {
          a {
            color: var(--background);
          }
          &:last-of-type {
            display: none;
          }
        }
      }
    }

    .nav-visible {
      width: 60vw;
      visibility: visible;
      opacity: 1;
    }
  }

    }
